<template>
  <div id="app">
    <router-view></router-view>
    <BackTop></BackTop>
  </div>
</template>

<script>
export default {
  name: "app",
  created() {

  },
  methods: {
  },
};
</script>

<style>
a {
  text-decoration: none;
  color: inherit;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: inherit;
}

.ivu-page-item,.ivu-page-next,.ivu-page-prev{
  height: 52px;
  min-width: 52px;
  line-height: 50px;
  border-radius: 1px;
}
</style>