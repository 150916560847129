export default [
    //主页数据
    {
        path:"",
        component:() => import('@/components/GuestFrame.vue'),
        children:[
            {
                path:"/",
                name:"index",
                components:{
                    default:() => import('@/components/Index/IndexContent.vue'),
                },
            },
        ]
        
    },

    //服务
    {
        path:"",
        component:() => import('@/components/GuestFrame.vue'),
        children:[
            {
                path:"/services_list/:choosed",
                name:"servicesList",
                components:{
                    default:() => import('@/components/Services/ServiceList.vue'),
                },
                props: {
                    default: true
                },
            },
            {
                path:"/service/:serviceId",
                name:"service",
                components:{
                    default:() => import('@/components/Services/ServiceItem.vue'),
                },
                props: {
                    default: true
                },
            },
            {
                path:"/industries_list/:choosed",
                name:"industriesList",
                components:{
                    default:() => import('@/components/Industries/IndustryList.vue'),
                },
                props: {
                    default: true
                },
            },
            {
                path:"/industry/:industryId",
                name:"industry",
                components:{
                    default:() => import('@/components/Industries/IndustryItem.vue'),
                },
                props: {
                    default: true
                },
            },
            {
                path:"/news_list",
                name:"newsList",
                components:{
                    default:() => import('@/components/News/NewsList.vue'),
                },
            },
            {
                path:"/news/:newsId",
                name:"news",
                components:{
                    default:() => import('@/components/News/NewsItem.vue'),
                },
                props: {
                    default: true
                },
            },
            {
                path:"/introduce",
                name:"introduce",
                components:{
                    default:() => import('@/components/Introduces/Introduce.vue'),
                },
            },
            {
                path:"/contact",
                name:"contact",
                components:{
                    default:() => import('@/components/Introduces/Contact.vue'),
                },
            },
            {
                path:"/qualification_reputation/:choosed",
                name:"qualificationReputation",
                components:{
                    default:() => import('@/components/Introduces/QualificationReputation.vue'),
                },
                props: {
                    default: true
                },
            },
            {
                path:"/development_history",
                name:"developmentHistory",
                components:{
                    default:() => import('@/components/Introduces/DevelopmentHistory.vue'),
                },
            },
            {
                path:"/file_list/:choosed",
                name:"fileList",
                components:{
                    default:() => import('@/components/Archives/FileList.vue'),
                },
                props: {
                    default: true
                },
            },
            {
                path:"/recruiting_list",
                name:"recruitingList",
                components:{
                    default:() => import('@/components/Recruiting/RecruitingList.vue'),
                },
            },
            {
                path:"/job_description/:positionId",
                name:"jobDescription",
                components:{
                    default:() => import('@/components/Recruiting/JobDescription.vue'),
                },
                props: {
                    default: true
                },
            },
            
        ]
        
    },

    
]